import React from 'react'
import Checkbox from '../../../../components/Checkbox/Checkbox';
import { TextInput } from '../../../../components/InputFields/TextInput';
import { DatePickerComponent } from '../../../../components/DatePicker/DatePicker';
import TextRadio from '../../../../components/InputFields/TextRadio';
import { TextArea } from '../../../../components/InputFields/TextArea';
import moment from 'moment';

const headCells = [
    { id: "type", label: "Type", sort: false, width: "110px" },
    { id: "memberName", label: "Member Name", sort: false, width: "200px" },
    { id: "creditNoteDate", label: "Credit Note Date", sort: false, width: "160px" },
    { id: "hardCopy", label: "Hard Copy Voucher", sort: false, width: "160px" },
    { id: "referenceInvoice", label: "Reference Invoice No.", sort: false, width: "160px" },
    { id: "gstApplicable", label: "GST Applicable", sort: false, width: "120px" },
];

const serviceOptions = [
    { name: "service", value: "Y", label: "Service" },
    { name: "service", value: "N", label: "Goods" },
];


function ExcelDataTable({ uploadingExcelData, setUploadingExcelData }) {

    return (
        <div>
            <table>
                <thead className="text-[#AAAAAA] font-semibold" >
                    <tr>
                        <th className={`border-y p-2 text-[13px] border-[#DDD] text-left w-[35px]`}>
                            <Checkbox
                                initialchecked={!uploadingExcelData?.find(item => !item.selected)}
                                onChange={(e) => {
                                    setUploadingExcelData(prev => {
                                        let newData = [...prev]
                                        newData.forEach(item => {
                                            item.selected = e.target.checked;
                                        })
                                        return newData
                                    })
                                }}
                            />
                        </th>
                        {
                            headCells?.map((item) => (
                                <th className={`border-y py-2 text-[13px] border-[#DDD] text-left w-[${item.width}]`} >{item.label}</th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        uploadingExcelData?.map((item, index) => (
                            item.error ?
                                <tr>
                                    <td className='px-2 py-3'>
                                        <Checkbox
                                            initialchecked={false}
                                            disabled={true}
                                        />
                                    </td>
                                    <td colSpan={6} className="py-4 text-xs font-semibold text-justify break-all cursor-pointer text-red-650 text-wrap">
                                        {item.massage}
                                    </td>
                                </tr>
                                :
                                <>
                                    <tr className='align-top bg-[#F4F5F8]'>
                                        <td className='px-2 py-3'>
                                            <Checkbox
                                                initialchecked={item.selected}
                                                onChange={(e) => {
                                                    setUploadingExcelData(prev => {
                                                        let newData = [...prev]
                                                        newData[index].selected = e.target.checked;
                                                        return newData;
                                                    })
                                                }}
                                            />
                                        </td>
                                        <td className='py-3'>
                                            <TextInput
                                                className='w-24'
                                                value={item.type}
                                                onChange={(e) => {
                                                    setUploadingExcelData(prev => {
                                                        let newData = [...prev]
                                                        newData[index].type = e.target.value;
                                                        return newData;
                                                    })
                                                }}
                                                disabled={true}
                                            />
                                        </td>
                                        <td className='py-3 text-[13px]'>
                                            {item.member_glacc}
                                        </td>
                                        <td className='py-3 text-[13px]'>
                                            <DatePickerComponent
                                                defaultValue={item.date ? new Date(item.date) : null}
                                                className='w-36 h-9'
                                                onDateChange={(selectedDate) => {
                                                    setUploadingExcelData(prev => {
                                                        let newData = [...prev]
                                                        newData[index].date = moment(selectedDate).format('YYYY-MM-DD');
                                                        return newData;
                                                    })
                                                }}
                                            />
                                        </td>
                                        <td className='py-3'>
                                            <TextInput
                                                className='w-32'
                                                value={item.voucher_no}
                                                onChange={(e) => {
                                                    setUploadingExcelData(prev => {
                                                        let newData = [...prev]
                                                        newData[index].voucher_no = e.target.value;
                                                        return newData;
                                                    })
                                                }}
                                            />
                                        </td>
                                        <td className='py-3'>
                                        </td>
                                        <td className='py-3 text-[13px]'>
                                            {item.gst_applicable === 'Y' ? 'Yes' : 'No'}
                                        </td>

                                    </tr>
                                    <tr className='align-top'>
                                        <td className='px-2 py-3' colSpan={7}>
                                            <table className='w-full' cellPadding={5} cellSpacing={1}>
                                                <thead className="text-[#AAAAAA] font-semibold" >
                                                    <tr>
                                                        <th className='text-[13px] text-[#AAAAAA] font-semibold mb-3'>Cause</th>
                                                        <th className='text-[13px] text-[#AAAAAA] font-semibold mb-3'>Debit A/C</th>
                                                        <th className='text-[13px] text-[#AAAAAA] font-semibold mb-3'>HSN Code</th>
                                                        <th className='text-[13px] text-[#AAAAAA] font-semibold mb-3'>Service/Good</th>
                                                        <th className='text-[13px] text-[#AAAAAA] font-semibold mb-3'>Amount (Rs.)</th>

                                                    </tr>
                                                </thead>
                                                <tbody className='text-[13px] font-semibold'>
                                                    <tr>
                                                        <td >{item.cause}</td>
                                                        <td>{item.creditDetails}</td>
                                                        <td>
                                                            <TextInput
                                                                value={item.hsn_code || ''}
                                                                onChange={(e) => {
                                                                    setUploadingExcelData(prev => {
                                                                        let newData = [...prev]
                                                                        newData[index].hsn_code = e.target.value;
                                                                        return newData;
                                                                    })
                                                                }}
                                                                className='w-32'
                                                            />
                                                        </td>
                                                        <td>
                                                            <TextRadio
                                                                label={''}
                                                                name={`service${index}`}
                                                                defaultSelected={item.service}
                                                                options={serviceOptions}
                                                                className={'my-1.5 text-[12px]'}
                                                                onChange={(value) => {
                                                                    setUploadingExcelData(prev => {
                                                                        let newData = [...prev]
                                                                        newData[index].service = value;
                                                                        return newData;
                                                                    })
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <TextInput
                                                                className='w-32 mb-1'
                                                                type='number'
                                                                value={item.amount || 0}
                                                                disabled={true}
                                                            />
                                                        </td>
                                                    </tr>
                                                    {item.gst_applicable === 'Y' && item.sgst_glcode && <tr>
                                                        <td colSpan={3}></td>
                                                        <td className='text-right'>SGST</td>
                                                        <td>
                                                            <TextInput
                                                                disabled={true}
                                                                className='w-32 mb-1'
                                                                type='number'
                                                                value={item.sgst_amount || 0}
                                                            />
                                                        </td>
                                                    </tr>}
                                                    {item.gst_applicable === 'Y' && item.cgst_glcode && <tr>
                                                        <td colSpan={3}></td>
                                                        <td className='text-right'>CGST</td>
                                                        <td>
                                                            <TextInput
                                                                disabled={true}
                                                                className='w-32 mb-1'
                                                                type='number'
                                                                value={item.cgst_amount || 0}
                                                            />
                                                        </td>
                                                    </tr>}
                                                    {item.gst_applicable === 'Y' && item.igst_glcode && <tr>
                                                        <td colSpan={3}></td>
                                                        <td className='text-right'>IGST</td>
                                                        <td>
                                                            <TextInput
                                                                disabled={true}
                                                                className='w-32 mb-1'
                                                                type='number'
                                                                value={item.igst_amount || 0}

                                                            />
                                                        </td>
                                                    </tr>}
                                                    {item.roundoff_glcode && item.roundoff === "Yes" && <tr>
                                                        <td colSpan={3}></td>
                                                        <td className='text-right'>Round Off</td>
                                                        <td>
                                                            <TextInput
                                                                disabled={true}
                                                                className='w-32 mb-1'
                                                                type='number'
                                                                value={item.roundoff_amount || 0}

                                                            />
                                                        </td>
                                                    </tr>}
                                                    <tr>
                                                        <td colSpan={3}></td>
                                                        <td className='text-right'>Grand Total</td>
                                                        <td>
                                                            <TextInput
                                                                disabled={true}
                                                                className='w-32 mb-1'
                                                                type='number'
                                                                value={item.grand_total || 0}

                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='text-center'>Remark</td>
                                                        <td colSpan={4}>
                                                            <TextArea
                                                                className='w-[500px]'
                                                                row={3}
                                                                type='number'
                                                                value={item.remark || ''}
                                                                onChange={(e) => {
                                                                    setUploadingExcelData(prev => {
                                                                        let newData = [...prev]
                                                                        newData[index].remark = e.target.value;
                                                                        return newData;
                                                                    })
                                                                }}
                                                            />
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>

                                    </tr>
                                </>
                        ))
                    }

                </tbody>
            </table>
        </div>
    )
}

export default ExcelDataTable